import React from "react";
import "./about.css";
import Card from "../../components/Card/Card";
import logo_medisimus_little from "../../assets/logo_medisimus_little.png";
import photo_profil from "../../assets/photo-profil.jpg";

const About = () => (
  <div id="about" className="about-container">
    <h2 className="about-big-title">À Propos</h2>
    <h3 className="about-title">Tous Nos Vacataires Sont Formés En Simulation Médicale</h3>
    <div className="cards-container">
      <Card
        logo={logo_medisimus_little}
        alt="logo médisimusplus"
        title="Fondateur :"
        profil={photo_profil}
        altProfil="profil de Gilles Cholley"
        name="Gilles Cholley"
        describe="Anesthésiste Réanimateur, Enseignant/Formateur Diplôme Universitaire de Simulation Médical.
            DU d'Ecographie et d'AR en Chirurgie Cardio Thoracique, Médecine de Montagne et de Catastrophe. "
        social="https://www.linkedin.com/in/gilles-cholley-04282546/"
      />
    </div>
  </div>
);

export default About;
