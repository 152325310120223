import React from "react";
import "./vr.css";
import vr_panoramique from "../../assets/vr-panoramique.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPersonChalkboard,
    faPlus,
    faScrewdriverWrench,
    faStarOfLife,
} from "@fortawesome/free-solid-svg-icons";

const Vr = () => (
    <div className="vr-container">
        <div className="vr-content">
            <h2 className="vr-big-title">
                Introduction à La Simulation En Réalité Virtuelle
            </h2>
            <div className="vr-text">
                <div className="vr-description">
                    Premiers pas en Réalité Virtuelle assistés par nos soins
                </div>
                <h3 className="vr-title">
                    Grâce à La Réalité Virtuelle Nous Pouvons Vous Proposer :
                </h3>
                <div className="vr-type">
                    <ul className="vr-list-1">
                        <li>
                            <FontAwesomeIcon
                                icon={faStarOfLife}
                                className="vr-icon-little"
                            />
                            Jeux Médicaux En Situation De Feu Au Bloc Opératoire
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faStarOfLife}
                                className="vr-icon-little"
                            />
                            Facteurs Humains à Travers Les Simulateurs De
                            Pilotage (avion planeur)
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faStarOfLife}
                                className="vr-icon-little"
                            />
                            Différents Tutoriels
                        </li>
                    </ul>
                </div>
                <div className="vr-images">
                    <div className="vr-image-1">
                        <img
                            width="100%"
                            height="100%"
                            src={vr_panoramique}
                            alt="personnel soignant utilisant un casque de réalité virtuelle"
                        ></img>
                    </div>
                </div>
                <div className="vr-tools">
                    <h3 className="vr-title">
                        <FontAwesomeIcon
                            icon={faScrewdriverWrench}
                            className="vr-icon-2"
                        />
                        Matériel :
                    </h3>
                    <ul className="vr-list-2">
                        <li>
                            3 casques de réalité virtuelle coordonnés en réseaux
                        </li>
                    </ul>
                </div>
                <div className="vr-watchers">
                    <h3 className="vr-title">
                        <FontAwesomeIcon
                            icon={faPersonChalkboard}
                            className="vr-icon-2"
                        />
                        Encadrants :
                    </h3>
                    Médecin anésthésiste, chirurgien, infirmière, sage-femme,
                    enseignants/formateurs en simulation médicale
                </div>
                <div className="vr-interest">
                    <h3 className="vr-title">
                        <FontAwesomeIcon icon={faPlus} className="vr-icon-2" />
                        Intérêts :
                    </h3>
                    <ul className="vr-list-3">
                        <li>
                            <FontAwesomeIcon
                                icon={faStarOfLife}
                                className="vr-icon-little"
                            />
                            Pédagogique ludique, up-to-date
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faStarOfLife}
                                className="vr-icon-little"
                            />
                            Incidences économique positives
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faStarOfLife}
                                className="vr-icon-little"
                            />
                            Entraînement personnalisé
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
);

export default Vr;
