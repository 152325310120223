import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faStarOfLife, faUserDoctor, faPlus } from "@fortawesome/free-solid-svg-icons";
import mannequin from "../../assets/mannequin.jpg";
import conference from "../../assets/animation.jpg";
import "./plus.css";

const Plus = () => (
  <div id="plus" className="plus-container">
    <h2 className="plus-big-title">En Plus</h2>
    <h3 className="about-title">Participations & Animations Réalisées</h3>
    <div className="plus-content">
      <div className="plus-participation-container">
        <h3 className="plus-title">Participation à des simulations au bloc opératoire en équipe</h3>
        <h3 className="plus-participation-title">
          <FontAwesomeIcon icon={faHospital} className="plus-participation-icon-1" />
          Établissements de soins
        </h3>
        <div className="plus-participation-container-list-picture">
          <ul className="plus-list-1">
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a
                href="https://hopital-prive-jean-mermoz-lyon.ramsaysante.fr/"
                className="plus-link"
                rel="noreferrer"
                target="_blank"
              >
                Lyon (Mermoz)
                <p className="plus-p">Hôpital Privé Jean Mermoz</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a href="https://www.groupeconfluent.fr/fr/" className="plus-link" rel="noreferrer" target="_blank">
                Nantes (N.C.N)
                <p className="plus-p">Hôpital Privé du Confluent</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a href="http://www.courlancy-sante.com/courlancy" className="plus-link" rel="noreferrer" target="_blank">
                Reims (Courlancy)
                <p className="plus-p">Polyclinique de Courlancy</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a href="https://www.diaconat-mulhouse.fr/" className="plus-link" rel="noreferrer" target="_blank">
                Mulhouse (C.D.F)
                <p className="plus-p">Cliniques du Diaconat Fonderie & Roosevelt</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a
                href="https://metropole.nantes.fr/infonantes/equipement/E1863-polyclinique-de-latlantique"
                className="plus-link"
                rel="noreferrer"
                target="_blank"
              >
                Nantes (P.C.A)
                <p className="plus-p">Polyclinique de l'Atlantique</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a href="http://www.clinique-louispasteur.com/" className="plus-link" rel="noreferrer" target="_blank">
                Nancy (C.L.P)
                <p className="plus-p">Clinique Louis Pasteur</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a
                href="https://clinique-convert-bourg-en-bresse.ramsaysante.fr/"
                className="plus-link"
                rel="noreferrer"
                target="_blank"
              >
                Bourg en Bresse (C.C)
                <p className="plus-p">Clinique Convert</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a href="http://www.clinique-tivoli.com/" className="plus-link" rel="noreferrer" target="_blank">
                Bordeaux (Tivoli)
                <p className="plus-p">Clinique Tivoli</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a href="https://www.groupehpl.com/2caps/" className="plus-link" rel="noreferrer" target="_blank">
                Coquelles (les 2 Caps)
                <p className="plus-p">Clinique des 2 Caps</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a
                href="https://hopital-prive-arras-les-bonnettes.ramsaysante.fr/"
                className="plus-link"
                rel="noreferrer"
                target="_blank"
              >
                Arras (les Bonnettes)
                <p className="plus-p">Hôpital Privé Arras les Bonnettes</p>
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faStarOfLife} className="plus-icon-little" />
              <a
                href="https://www.elsan.care/fr/clinique-notre-dame-thionville"
                className="plus-link"
                rel="noreferrer"
                target="_blank"
              >
                Thionville
                <p className="plus-p">Clinique Notre-Dame</p>
              </a>
            </li>
          </ul>
          <div className="plus-participation-picture">
            <img width="100%" height="100%" src={mannequin} alt="chirurgien s'exerçant sur un mannequin"></img>
          </div>
        </div>
      </div>
      <div className="plus-animation-container">
        <h3 className="plus-participation-title">
          <FontAwesomeIcon icon={faUserDoctor} className="plus-participation-icon-1" />
          Animations et autres activités
        </h3>
        <div className="plus-activity-container">
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            Animations de plusieurs jours sur des congrès :<br />
            la{" "}
            <a href="https://sofrasims.org/" className="plus-link" rel="noreferrer" target="_blank">
              SoFraSimS
            </a>
            , la{" "}
            <a href="https://www.sofcot.fr/" className="plus-link" rel="noreferrer" target="_blank">
              SOFCOT
            </a>
            , la{" "}
            <a href="https://www.sofcpre.fr/" className="plus-link" rel="noreferrer" target="_blank">
              SoFCPRE
            </a>
            , le{" "}
            <a href="https://congres2022.pompiers.fr/" className="plus-link" rel="noreferrer" target="_blank">
              congrès national des Sapeurs-Pompiers
            </a>
          </p>
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            Découverte et formation en lycée général
          </p>
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            30 jours de formation au sein du{" "}
            <a
              href="https://www.pompiers.fr/pompiers/nous-connaitre/organisation-des-sapeurs-pompiers-en-france#:~:text=Le%20SDIS%20est%20charg%C3%A9%20de,et%20coordonne%20les%20diff%C3%A9rentes%20interventions."
              className="plus-link"
              rel="noreferrer"
              target="_blank"
            >
              SDIS
            </a>
          </p>
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            Participation active sous forme de cours et d'ateliers au D.U de simulation médicale de Lyon 1
          </p>
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            Participation active au diplôme de formation de technicien en simulation
          </p>
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            Formation de 6 jours sur le thème d'un incendie au bloc opératoire pour les hôpitaux de fort de France et
            Pointe à Pitre
          </p>
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            Construction et exploitation d'un jeu en réalité virtuelle au sujet d'un incendie au bloc opératoire
            impliquant 3 joueurs coordonnés dans le même lieu virtuel, animation, fabrication de quiz.{" "}
            <a href="https://simforhealth.fr/" className="plus-link" rel="noreferrer" target="_blank">
              SimforHealth
            </a>
          </p>
          <p className="plus-p">
            <FontAwesomeIcon icon={faPlus} className="plus-icon-little-2" />
            Adaptation de jeu sérieux de pilotage en vol moteur et en vol libre à l'enseignement des facteurs humains
            (importance des check-lists, résolution des problèmes en équipe, mise en place d'aides cognitives papier ou
            informatique)
          </p>
        </div>
        <div className="plus-animation-picture">
          <img width="100%" height="100%" src={conference} alt="chirurgien s'exerçant sur un mannequin"></img>
        </div>
      </div>
    </div>
  </div>
);

export default Plus;
