import React from "react";
import SimpleSlider from "../../components/Carroussel/Caroussel";
import "./conferences.css";

const Conferences = () => (
  <div id="conferences" className="conferences-container">
    <div className="conferences-content">
      <SimpleSlider />
    </div>
  </div>
);

export default Conferences;
