import React from "react";
import { LinkedinFilled } from "@ant-design/icons";
import "./card.css";

const Card = ({ logo, alt, title, name, describe, social, profil, altProfil }) => (
  <div className="card-container">
    <div className="card-content">
      <div className="card-logo">
        <img width="100%" height="100%" src={logo} alt={alt}></img>
      </div>
      <h3 className="card-title">{title}</h3>
      <div className="card-profil">
        <img width="100%" height="100%" src={profil} alt={altProfil}></img>
      </div>
      <h3 className="card-name">{name}</h3>
      <div className="card-description">{describe}</div>
      <div className="card-social">
        <a href={social} rel="noreferrer" target="_blank">
          <LinkedinFilled className="linkedin" />
        </a>
      </div>
    </div>
  </div>
);

export default Card;
