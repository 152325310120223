import React from "react";
import "./medical.css";
import medical_panoramique_2 from "../../assets/medical-panoramique-2.jpg";
import medical_panoramique from "../../assets/medical-panoramique-3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookMedical,
  faPeopleArrowsLeftRight,
  faPeopleGroup,
  faPersonChalkboard,
  faPlus,
  faScrewdriverWrench,
  faStarOfLife,
} from "@fortawesome/free-solid-svg-icons";

const Medical = () => (
  <div className="medical-container">
    <h1 className="medical-big-title">Simulations Médicales & Paramédicales</h1>
    <div className="medical-description">
      En séance In Situ, durée (8h) par équipe, abordant les facteurs techniques et les facteurs humains.
    </div>
    <div className="medical-content">
      <div className="medical-text">
        <div className="medical-left-column">
          <h3 className="medical-title">Nos Types De Simulations Proposés</h3>
          <div className="medical-simu-type-1">
            <span className="medical-simu-title">Simulation En Bloc Opératoire</span>
            <br />
            Avec chirurgien, anesthésiste, infirmière et leurs aides sur des scénarios réalistes
            <ul className="medical-simu-list">
              <li>
                <FontAwesomeIcon icon={faPeopleGroup} className="medical-icon-1" />5 équipes de 5
              </li>
              <li>
                <FontAwesomeIcon icon={faBookMedical} className="medical-icon-1" />5 scénarios successifs par jour
              </li>
              <li>
                <FontAwesomeIcon icon={faPeopleArrowsLeftRight} className="medical-icon-1" />
                Débriefing avec tout le groupe
              </li>
            </ul>
          </div>
          <div className="medical-simu-type-2">
            <span className="medical-simu-title">Simulation Service d'Urgence</span>
            <br />
            Pour groupe de gynécologues, urgentistes, médecins géneralistes, dentistes et radiologues
          </div>
          <div className="medical-simu-type-3">
            <span className="medical-simu-title">Simulation En Service Hospitalier</span>
            <br />
            Pour groupe de sage-femmes et/ou d'infirmières
          </div>
          <div className="medical-simu-type-4">
            <span className="medical-simu-title">Simulation Mise En Situation d'Urgence</span>
            <br />
            Industrie, lycées, services publics etc.
          </div>
          <div className="medical-image-1">
            <img width="100%" height="100%" src={medical_panoramique} alt="bannière médicale" />
          </div>
        </div>
        <div className="medical-right-column">
          <div className="medical-tools">
            <h3 className="medical-title">Nos Simulations Sont Composées</h3>
            <h3 className="medical-title">
              <FontAwesomeIcon icon={faScrewdriverWrench} className="medical-icon-2" />
              Matériel :
            </h3>
            <h4>Laboratoire De Simulation Implantable Comportant</h4>
            <ul className="medical-simu-list">
              <li>
                <FontAwesomeIcon icon={faStarOfLife} className="medical-icon-little" />
                Un mannequin haute-fidélité
              </li>
              <li>
                <FontAwesomeIcon icon={faStarOfLife} className="medical-icon-little" />
                Un poste de commande
              </li>
              <li>
                <FontAwesomeIcon icon={faStarOfLife} className="medical-icon-little" />
                Des caméras fixes et mobiles avec système de son performant et transmission/projection en salle de
                debriefing
              </li>
            </ul>
          </div>
          <div className="medical-watchers">
            <h3 className="medical-title">
              <FontAwesomeIcon icon={faPersonChalkboard} className="medical-icon-2" />
              Encadrants :
            </h3>
            Médecin anésthésiste, chirurgien, infirmière, sage-femme, enseignants/formateurs en simulation médicale
          </div>
          <div className="medical-interest">
            <h3 className="medical-title">
              <FontAwesomeIcon icon={faPlus} className="medical-icon-2" />
              Intérêts :
            </h3>
            <ul className="medical-simu-list">
              <li>
                <FontAwesomeIcon icon={faStarOfLife} className="medical-icon-little" />
                Formation en immersion dans votre environnement
              </li>
              <li>
                <FontAwesomeIcon icon={faStarOfLife} className="medical-icon-little" />
                Incidences économiques positives
              </li>
              <li>
                <FontAwesomeIcon icon={faStarOfLife} className="medical-icon-little" />
                Pédagogie collective
              </li>
            </ul>
          </div>
          <div className="medical-image-2">
            <img width="100%" height="100%" src={medical_panoramique_2} alt="équipe médicale main dans la main"></img>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Medical;
