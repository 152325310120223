import React from "react";
import CustomButton from "./Button.styled";

const Button = ({ variant, onClick, startIcon, endIcon, color, size, title }) => {
  return (
    <CustomButton variant={variant} onClick={onClick} startIcon={startIcon} endIcon={endIcon} color={color} size={size}>
      {title}
    </CustomButton>
  );
};
export default Button;
