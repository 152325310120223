import React from "react";
import "./simulations.css";
import Medical from "../../components/Medical/Medical";
import Vr from "../../components/Vr/Vr";

const Simulations = () => (
    <div id="simulations" className="simulations-container">
        <div className="simulations-content">
            <div className="simulations-medical-container">
                <Medical />
            </div>
            <div className="simulations-vr-container">
                <Vr />
            </div>
        </div>
    </div>
);

export default Simulations;
