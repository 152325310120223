import React, { useState } from "react";
import logo_medisimus from "../../assets/logo_medisimus.png";
import "./header.css";

const Header = () => {
  const [showLinks, setShowLinks] = useState(false);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };

  const scrollTo = (id) => {
    let target = document.getElementById(id);
    target && target.scrollIntoView({ behavior: "smooth", block: "start" });
    setShowLinks(false);
  };

  return (
    <nav className={`header-container ${showLinks ? "show-nav" : "hide-nav"} `}>
      <div className="header-logo-medisimus" onClick={() => scrollTo("simulations")}>
        <img width="100%" height="100%" src={logo_medisimus} alt="logo médisimus +" />
      </div>
      <ul className="header-links-container">
        <li className="header-links-li slideInDown-1">
          <button onClick={() => scrollTo("simulations")} className="header-links-button">
            Simulations
          </button>
        </li>
        <li className="header-links-li slideInDown-2">
          <button onClick={() => scrollTo("conferences")} className="header-links-button">
            Conférences & Locations
          </button>
        </li>
        <li className="header-links-li slideInDown-3">
          <button onClick={() => scrollTo("about")} className="header-links-button">
            À Propos
          </button>
        </li>
        <li className="header-links-li slideInDown-4">
          <button onClick={() => scrollTo("plus")} className="header-links-button">
            Plus +
          </button>
        </li>
        <li className="header-links-li slideInDown-5">
          <button onClick={() => scrollTo("contact")} className="header-links-button">
            Contact
          </button>
        </li>
      </ul>
      <button className="navbar-burger" onClick={handleShowLinks}>
        <span className="burger-bar"></span>
      </button>
    </nav>
  );
};
export default Header;
