import React, { useState, useEffect } from "react";
import { TopButtonContainer, TopButton } from "./BackToTopButton.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";

const BackToTopButton = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <TopButtonContainer>
      {backToTopButton && (
        <TopButton onClick={scrollUp}>
          <FontAwesomeIcon icon={faSortUp} />
        </TopButton>
      )}
    </TopButtonContainer>
  );
};
export default BackToTopButton;

/*<div className="btn-up-container">
        {backToTopButton && (
          <div className="btn-up" onClick={scrollUp}>
            <span>🔼</span>
            <span>Haut De Page</span>
          </div>
        )}
        </div>*/
