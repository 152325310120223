import styled from "styled-components";
export const TopButtonContainer = styled.div``;

export const TopButton = styled.div`
    font-size: 1.8em;
    height: 50px;
    width: 50px;
    background: var(--orange-primary);
    border-radius: 6px;
    cursor: pointer;
    color: var(--white);
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: all 0.3s ease;
    :hover {
        transition-duration: 0.1s;
        background-color: #f2b452;
    }
    :after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 6px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s;
        box-shadow: 0 0 10px 40px #edbea1;
    }
    :active:after {
        box-shadow: 0 0 0 0 #edbea1;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 1;
        transition: 0s;
    }

    @media (max-width: 767px) {
        font-size: 1.2em;
        height: 35px;
        width: 35px;
    }
`;
