import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseMedical, faLocationDot, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { LinkedinFilled } from "@ant-design/icons";
import ContactForm from "../../components/ContactForm/ContactForm";

import "./contact.css";

const Contact = () => (
  <div id="contact" className="contact-container">
    <h2 className="contact-big-title">Contactez Nous</h2>
    <div className="contact-all-content">
      <div className="contact-left-content">
        <p className="contact-left-text">
          Ci-dessous vous trouverez les coordonnées de <span className="contact-name-medisimus">MédiSimus +</span>.
          <br />
          Pour toute demande d'information, de devis ou de prise de rendez-vous, n'hésitez pas à nous contacter par
          téléphone, email ou directement par l'envoi d'un email en utilisant l'interface dédiée.
        </p>
        <div className="contact-left-infos">
          <FontAwesomeIcon icon={faHouseMedical} className="contact-left-icons" />
          <span className="contact-name-medisimus">MédiSimus +</span>siret : 90193996700013
        </div>
        <div className="contact-left-infos">
          <FontAwesomeIcon icon={faLocationDot} className="contact-left-icons" />
          73420 Viviers-Du-Lac
        </div>
        <div className="contact-left-infos">
          <FontAwesomeIcon icon={faPhone} className="contact-left-icons" />
          06 71 63 62 22
        </div>
        <div className="contact-left-infos">
          <FontAwesomeIcon icon={faEnvelope} className="contact-left-icons" />
          medisimus.plus@zohomail.eu
        </div>
        <div className="contact-left-infos">
          <a href="https://www.linkedin.com/in/gilles-cholley-04282546/">
            <LinkedinFilled className="contact-left-icon-linkedin" />
          </a>
          <a
            href="https://www.linkedin.com/in/gilles-cholley-04282546/"
            className="contact-left-infos-name"
            rel="noreferrer"
            target="_blank"
          >
            Gilles Cholley
          </a>{" "}
          <span className="contact-name-medisimus-2">MédiSimus +</span>
        </div>
      </div>
      <div className="contact-right-content"></div>
      <ContactForm />
    </div>
  </div>
);

export default Contact;
