import React, { Component } from "react";
import Slider from "react-slick";

import ConferencesImg from "../../assets/conferences.jpg";
import RentImg from "../../assets/rent.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBed,
    faDesktop,
    faTired,
    faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

import "./caroussel.css";

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        const scrollTo = (id) => {
            let target = document.getElementById(id);
            target &&
                target.scrollIntoView({ behavior: "smooth", block: "start" });
        };
        return (
            <div>
                <Slider {...settings}>
                    <div className="caroussel-content-1">
                        <div className="caroussel-img">
                            <img
                                width="100%"
                                height="100%"
                                src={ConferencesImg}
                                alt="médecin avec un stétoscope"
                            />
                        </div>

                        <div className="caroussel-text-1">
                            <span className="caroussel-text-title">
                                Cycles De Conférences
                            </span>
                            <ul className="caroussel-text-list">
                                <li>
                                    <FontAwesomeIcon
                                        icon={faBed}
                                        className="caroussel-icon"
                                    />
                                    Qualité du sommeil et son impact sur la
                                    performance
                                </li>
                                <li>
                                    <FontAwesomeIcon
                                        icon={faDesktop}
                                        className="caroussel-icon"
                                    />
                                    Les effets secondaires liés à l'utilisation
                                    des écrans
                                </li>
                                <li>
                                    <FontAwesomeIcon
                                        icon={faTired}
                                        className="caroussel-icon"
                                    />
                                    Le stress
                                </li>
                                <li>
                                    <FontAwesomeIcon
                                        icon={faCommentDots}
                                        className="caroussel-icon"
                                    />
                                    La communication
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="caroussel-content-2">
                        <div className="caroussel-img">
                            <img
                                width="100%"
                                height="100%"
                                src={RentImg}
                                alt="médecin utilisant un ordinateur"
                            />
                        </div>

                        <div className="caroussel-text-2">
                            <span className="caroussel-text-title">
                                Location d'Un Système Vidéo De Laboratoire De
                                Simulation
                            </span>

                            <p className="caroussel-text-p">
                                Pour de plus amples informations, n'hésitez pas
                                à nous{" "}
                                <button
                                    className="caroussel-button"
                                    onClick={() => scrollTo("contact")}
                                >
                                    Contacter
                                </button>{" "}
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}
