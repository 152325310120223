import React from "react";
import Simulations from "./containers/Simulations/Simulations";
import Conferences from "./containers/Conferences/Conferences";
import About from "./containers/About/About";
import Plus from "./containers/Plus/Plus";
import Contact from "./containers/Contact/Contact";
import Header from "./containers/Header/Header";
import BackToTopButton from "./components/BackToTopButton/BackToTopButton";
import Footer from "./containers/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "./app.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <div className="main-container">
      <Header />
      <main className="main">
        <Simulations />
        <Conferences />
        <About />
        <Plus />
        <Contact />
        <BackToTopButton />
        <ToastContainer />
      </main>
      <Footer />
    </div>
  );
};
export default App;
