import React from "react";
import "./footer.css";
import logo_medisimus from "../../assets/logo_medisimus.png";
import logo_brother from "../../assets/logo-brother.png";

const Footer = () => (
  <div className="footer-container">
    <div className="footer-content">
      <div className="footer-logo">
        <img width="100%" height="100%" src={logo_medisimus} alt="logo médisimus + footer" />
      </div>
      <div className="footer-logo-brother">
        <img width="100%" height="100%" src={logo_brother} alt="logo Brother's Browser" />
      </div>
    </div>
  </div>
);

export default Footer;
