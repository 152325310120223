import styled from "styled-components";
import TextField from "@mui/material/TextField";

const CustomInput = styled(TextField)`
    width: ${({ width }) => width};
    background-color: var(--white);
    color: #000;
    border-radius: 6px;
    @media (max-width: 767px) {
        width: 100%;
    }
`;
export default CustomInput;
